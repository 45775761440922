<template>
  <van-cell-group>
    <van-field readonly clickable class="qrcodefiel" name="qrcode" label="手机扫码" placeholder="点击图标扫码" />
    <van-icon name="scan" size="25" class="qrcodeiconcoy" @click="qrcodeapi" />
    <van-field readonly clickable name="picker" :value="form.degreeUrgency" label="紧急程度" placeholder="点击选择紧急程度"
      @click="showPicker = true" />
    <van-popup v-model="showPicker" position="bottom">
      <van-picker show-toolbar :columns="columns" @confirm="onConfirm" @cancel="showPicker = false" />
    </van-popup>

    <div>
      <van-field readonly clickable label="乘车人员" required :rules="[{
      required: true, message: '不能为空', validator: value => {
        return form.riderNumber > form.rider.length - 1
      }
    }]" name="type" :value="form.rider" placeholder="请选择" @click="houseutilizationType" />
      <van-field readonly clickable label="乘车人数" required :rules="[{ required: true, message: '不能为空', }]" name="picker"
        :value="form.riderNumber" placeholder="请选择" @click="ishouseutilizationType = true" />
      <van-popup v-model="ishouseutilizationType" position="bottom">
        <van-picker show-toolbar :columns="houseutilizationColumns" @confirm="onConfirmiHouseutilization"
          @cancel="ishouseutilizationType = false" />
      </van-popup>
    </div>

    <van-field v-model="form.initiatorPhone" name="联系电话" label="联系电话" placeholder="联系电话"
      :rules="[{ required: true, message: '请输入联系电话' }]" />
    <van-field v-model="form.startingPoint" label="出发地点" placeholder="请输入出发地点" />
    <van-field readonly clickable name="picker" :value="form.destinationArea" label="目的地区域" placeholder="点击选择目的地区域"
      @click="showPickerDestinationArea = true" />
    <van-popup v-model="showPickerDestinationArea" position="bottom">
      <van-picker show-toolbar :columns="destinationAreaColumns" @confirm="onConfirmDestinationArea"
        @cancel="showPickerDestinationArea = false" />
    </van-popup>
    <van-field readonly clickable name="picker" :value="form.vehicleCategory" label="用车事由" placeholder="点击选择用车事由"
      @click="carmadeof = true" />
    <van-popup v-model="carmadeof" position="bottom">
      <van-picker show-toolbar :columns="carmadeofColumns" @confirm="onConfcarmadeof" @cancel="carmadeof = false" />
    </van-popup>

    <template v-if="form.vehicleCategory == '其他用车'">
      <van-field v-model="form.carDriven" name="其他用车" label="其他用车" placeholder="其他用车" required
        :rules="[{ required: true, message: '请输入其他用车' }]" />
    </template>
    <van-field v-model="form.specificDestination" label="具体目的地" placeholder="请输入具体目的地" />

    <van-field readonly clickable name="picker" :value="form.vehicleType" label="用车类别" placeholder="点击选择用车类别"
      @click="showPickervehicleType = true" />
    <van-popup v-model="showPickervehicleType" position="bottom">
      <van-picker show-toolbar :columns="vehicleTypeColumns" @confirm="onConfirmvehicleType"
        @cancel="showPickervehicleType = false" />
    </van-popup>
    <van-field readonly clickable name="datetimePicker" :value="form.useTime" label="用车时间" placeholder="点击选择时间"
      @click="showPickerUseTime = true" />
    <van-popup v-model="showPickerUseTime" position="bottom">
      <van-datetime-picker type="datetime" title="选择用车时间" @confirm="onConfirmUsrTime" :value="defaultTime"
        :filter="filter" @cancel="showPickerUseTime = false" />
    </van-popup>
    <van-field readonly clickable name="datetimePicker" :value="form.returnTime" label="返回时间" placeholder="点击返回时间"
      @click="showPickerReturnTime = true" />

    <van-popup v-model="showPickerReturnTime" position="bottom">
      <van-datetime-picker type="datetime" title="选择返回时间" @confirm="onConfirmReturnTime" :value="defaultTime"
        minute-step="5" :filter="filter" @cancel="showPickerReturnTime = false" />
    </van-popup>
    <van-field readonly clickable name="picker" :value="form.workingDay" label="日期类型" />
    <p style="text-align: left; margin-left: 15px;">流程</p>
    <p style=" color:#a3abab; text-align: left;    font-size: 14px;margin-left: 15px;">提交后，流程会在审核显示</p>
    <van-row style="color:#a3abab; text-align: left;  background-color: #f8f8f8;  padding: 15px;  ">
      <van-col span="24 ">
        <div style="font-size: 12px;">以下部分申请人无需填写</div>
      </van-col>
      <van-col span="24">
        <div style="border-bottom: 1px dashed #7a7a7a; ">

        </div>
      </van-col>
      <van-col span="24">
        <div style="font-size: 12px;">车辆安排</div>
      </van-col>
    </van-row>
    <van-field readonly clickable :rules="[{ required: true, message: '请点击选择所在部门' }]" name="picker"
      :value="form.department" label="所在部门" placeholder="点击选择所在部门" @click="showDepartmentsPicker = true" />

    <van-popup v-model="showDepartmentsPicker" position="bottom">
      <van-picker show-toolbar :columns="showDeparColumns" @confirm="showDepartmentsPickerCategory"
        @cancel="showDepartmentsPicker = false" />
    </van-popup>

    <div style="margin: 16px">
      <van-button round block type="info" @click="addUseCar" native-type="submit">提交
      </van-button>
    </div>

  </van-cell-group>
</template>
<script>
import Vue from "vue";
import {
  Tab,
  Tabs,
  Grid,
  GridItem,
  Image,
  ActionSheet,
  Divider,
  Cell,
  Popup,
  DatetimePicker,
  Picker,
  List,
  CellGroup,
  Calendar,
  Step,
  Steps,
  Row,
  Col,
  Icon,
  Toast,
  Checkbox,
  CheckboxGroup,
  RadioGroup,
  Radio
} from "vant";
import { Form } from 'vant';
import { Field } from 'vant';
import axios from 'axios';
import VueResource from 'vue-resource';
import * as dd from "dingtalk-jsapi";
Vue.use(Toast);
Vue.use(Calendar);
Vue.use(Picker);
Vue.use(Popup);
Vue.use(DatetimePicker);
Vue.use(Row);
Vue.use(Col);
Vue.use(CellGroup);
Vue.use(List);
Vue.use(Cell);
Vue.use(Divider);
Vue.use(ActionSheet);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Image);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Form);
Vue.use(Field);
Vue.use(Step);
Vue.use(Steps);
Vue.use(Icon);
Vue.use(VueResource);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Radio);
Vue.use(RadioGroup);
export default {
  name: 'Carrental',
  components: {
    VanField: Field,
  },
  data () {
    let today = new Date().toLocaleDateString('zh-CN'); // 获取当前日期，例如 "2022/12/1"
    let defaultDateTime = new Date(`${today} 08:30:00`); // 将日期和时间合并，得到默认时间
    return {
      defaultTime: defaultDateTime, // 设置默认时间为当天的8:30 AM
      departments: [{
        dept_id: '',
        name: ''
      }],
      showDot: false,
      inputDate: '',
      loading: false,
      result: [],
      ddConfigParams: null,
      people: [{
        avatar: "",
        emplId: '',
        name: ''
      }],
      copyUser: [{
        avatar: "",
        emplId: '',
        name: ''
      }],

      active: 0,
      listUserid: [],

      form: {
        initiatorPhone: '',
        requestAuthCode: '',
        registrar: [],
        logisticsofficer: [],
        logisticsSubUnit: [],
        logisticsFleet: [],
        startingPoint: '',
        copyUser: [],
        vehicleCategory: '会议用车',
        rider123: [],
        phone: [],
        houseutilizationValue: [],
        userid: [],
        degreeUrgency: '',
        rider: [],
        riderNumber:null,
        vehicleType: '一般公务用车',
        destinationArea: '',
        carDriven: '',
        useTime: '',
        returnTime: '',
        specificDestination: '',
        workingDay: '',
        department: '',
      },
      corpId: '',
      code: '',
      userInfo: null,
      name: '',
      rider: '',
      selectCarTime: '',
      useTime: '',
      showPickerUseTime: false,
      showPickerReturnTime: false,
      columns: ['普通', '紧急'],
      carmadeofColumns: ['会议用车', '机要通信用车', '检查调研用车', '老干用车', '公务接待用车', '工会慰问用车', '应急用车', '其他用车'],
      vehicleTypeColumns: ['一般公务用车', '业务用车'],
      destinationAreaColumns: ['长沙区域', '省内区域', '省外区域'],
      showDeparColumns: ['业务部', '开发部', '财务部'],
       //乘车人员
       ishouseutilizationType:false,
      houseutilizationColumns: [1,2,3,4,5,6,7,8,9,10],
      showPicker: false,
      showPickerDestinationArea: false,
      showDepartmentsPicker: false,
      showPickervehicleType: false,
      carmadeof: false,
      show: false,
      currentActionSheetTitle: "",
      currentActionSheetComponent: "",
    };
  },
  methods: {
   
    //扫码
    qrcodeapi () {
      var thas = this;
      dd.biz.util.scan({
        type: String,
        onSuccess: function (data) {
          const scanResult = JSON.parse(data.text);
          // 提取hyrq和hydd字段
          thas.form.startingPoint = scanResult.hydd;
          const hyrq = scanResult.hyrq;
          thas.onConfirmUsrTime(hyrq);
        },
        onFail: function () {


        }
      });

    },
    //时间间隔
    filter (type, options) {
      if (type === 'minute') {
        return options.filter(option => option % 5 === 0);
      }

      return options;
    },
    //获取身份

    getCarmadeof () {
      this.$axios.get('/getRole').then(resp => {
        // 将字符串转换为数组
        const parseStringToArray = str => str.replace(/\s/g, '').slice(1, -1).split(',');

        // 分别处理四个属性
        this.form.registrar = parseStringToArray(resp.data[0]);
        this.form.logisticsofficer = parseStringToArray(resp.data[1]);
        this.form.logisticsSubUnit = parseStringToArray(resp.data[2]);
        this.form.logisticsFleet = parseStringToArray(resp.data[3]);
        console.log(222, this.form.registrar, this.form.logisticsofficer);

      }).catch(error => { console.log("", error); });
    },
    //提交部门信息

    submitdepartmentapi () {
      const selectedDepartmentName = this.form.department;
      console.log("选择的部门名称", this.form.department);

      // 查找被选中部门的名称是否存在于部门列表中
      const selectedDepartment = this.departments.find(department => department.name === selectedDepartmentName);

      if (selectedDepartment) {
        const selectedDepartmentId = selectedDepartment.id;

        // 在这里执行提交逻辑，将 selectedDepartmentId 提交到后端
        this.$axios.get('/submitDepartment?departmentId=' + selectedDepartmentId).then(resp => {
          console.log("提交部门信息成功", resp.data);
        }).catch(error => {
          console.error("提交部门信息失败：", error);
        });
        console.log("提交部门信息,部门ID为:", selectedDepartmentId);
      } else {
        console.error("无效的部门信息，请选择有效的部门");
      }
    },
    //获取部门列表api
    getDepartments () {
      var thas = this;
      this.$axios.get('/getDepartment').then(resp => {
        console.log("获取部门列表数据", resp.data);
        // 使用map方法提取部门ID和名称
        thas.departments = resp.data.result.map(department => ({
          id: department.dept_id,
          name: department.name
        }));

        console.log("部门信息", thas.showDeparColumns);
      }).catch(error => {
        console.error("获取部门列表数据时出错：", error);
      });
    },
    //是否是工作日
    checkIfWeekday () {
      // 获取当前日期
      if (!this.inputDate) return;

      this.loading = true;
      const date = this.inputDate; // 使用用户输入的日期
      axios.get(`https://www.mxnzp.com/api/holiday/single/${date}?ignoreHoliday=false&app_id=prsefbf9hiuxrciq&app_secret=p6Ir6ADUVJkJPZ8aZ2TqUu3hVNDrlINW`)
        .then(response => {
          if (response.data.code === 1) {

            const holidayInfo = response.data.data.type;

            this.form.workingDay = this.getDayTypeText(holidayInfo);

          } else {
            console.error('接口返回错误:', response.data.msg);
          }
        })
        .catch(error => {
          console.error('请求失败:', error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    //根据checkIfWeekday返回判断是否是工作日
    getDayTypeText (holidayInfo) {
      switch (holidayInfo) {
        case 0:
          return '工作日';
        case 1:
          return '周末';
        case 2:
          return '法定节假日';
        default:
          return '未知类型';
      }
    },

    //获得联系人列表
    Contactlist () {
      console.log(22, this.listUserid);
      // 使用查询字符串附加参数到 URL
      this.$axios.get('/getRentralPhone?listUserid=' + this.listUserid).then(resp => {

        this.form.phone = resp.data;
        console.log("获取数据", this.form.phone);

      }).catch(error => {
        console.error("获取电话列表数据时出错：", error);
      });
    },


    houseutilizationType () {
      const vm = this;

      dd.biz.contact.choose({
        multiple: true, //是否多选：true多选 false单选； 默认true
        users: ['10001', '10002'], //默认选中的用户列表，员工userid；成功回调中应包含该信息
        corpId: 'dingbeeebed0cb750c8135c2f4657eb6378f', //企业id
        max: 1000, //人数限制，当multiple为true才生效，可选范围1-1500
        onSuccess: (data) => {
          if (data && data.length > 0) {

            vm.listUserid = data.map(contact => contact.emplId);
            console.log(11, vm.listUserid);
            vm.form.rider = data.map(contact => contact.name);
            vm.Contactlist();
          }



        },
        onFail: function (err) {
          console.error('选择联系人失败:', err);
        }
      });

    },

    DeleteApproval (index) {
      this.people.splice(index, 1)
    },
    DeleteCopy (index) {
      this.copyUser.splice(index, 1)
    },

    //获取免登授权码
    getDDConfigParams () {
      var that = this;// 保存this到that变量

      dd.ready(function () {
        dd.runtime.permission.requestAuthCode({
          corpId: "dingbeeebed0cb750c8135c2f4657eb6378f", // 企业id
          onSuccess: function (info) {
            // 通过该免登授权码可以获取用户身份
            that.codeJSON = JSON.stringify(info.code);
            that.form.requestAuthCode = '';
            that.form.requestAuthCode = info.code;
            console.log('免登授权码', that.form.requestAuthCode);
            that.UserPhone();
          }

        });

      });

    },
    //获得发起人联系电话
    UserPhone () {
      this.$axios.get('/getRentralPeople?requestAuthCode=' + this.form.requestAuthCode).then(resp => {
        console.log(11, resp.data);

        this.form.initiatorPhone = resp.data;
        this.getDDConfigParamscoy();
      }).catch(error => {
        console.error("获取电话数据时出错：", error);
      });
    },
    getDDConfigParamscoy () {
      var that = this;// 保存this到that变量

      dd.ready(function () {
        dd.runtime.permission.requestAuthCode({
          corpId: "dingbeeebed0cb750c8135c2f4657eb6378f", // 企业id
          onSuccess: function (info) {
            // 通过该免登授权码可以获取用户身份
            that.codeJSON = JSON.stringify(info.code);
            that.form.requestAuthCode = '';
            that.form.requestAuthCode = info.code;
            console.log('免登授权码', that.form.requestAuthCode);

          }

        });

      });

    },
    onConfirmiHouseutilization(value) {
      this.form.riderNumber = value;
      this.ishouseutilizationType = false;
    },
    onConfirm (value) {
      this.form.degreeUrgency = value;
      this.showPicker = false;
      console.log("dddd")
    },
    onConfirmvehicleType (value) {
      this.form.vehicleType = value;
      this.showPickervehicleType = false;
    },
    showDepartmentsPickerCategory (value) {
      //提交部门信息

      this.form.department = value;
      this.showDepartmentsPicker = false;
      this.submitdepartmentapi();
    },

    onConfcarmadeof (value) {
      this.carmadeof = false;
      this.form.vehicleCategory = value;
    },
    onConfirmDestinationArea (value) {
      this.form.destinationArea = value;
      this.showPickerDestinationArea = false;
    },
    onConfirmUsrTime (value) {
      const date = new Date(value);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const formattedDate = `${year}年${month}月${day}日${hours}时${minutes}分`;
      const monthcoy = String(month).padStart(2, '0');
      const daycoy = String(day).padStart(2, '0');
      this.inputDate = `${year}${monthcoy}${daycoy}`;
      this.form.useTime = formattedDate;
      this.showPickerUseTime = false;
      this.checkIfWeekday();

    },

    onConfirmReturnTime (value) {
      const currentDate = new Date(value);
      const year = currentDate.getFullYear();
      const month = currentDate.getMonth() + 1;
      const day = currentDate.getDate();
      const hours = currentDate.getHours();
      const minutes = currentDate.getMinutes();
      const formattedDate = `${year}年${month}月${day}日${hours}时${minutes}分`;
      this.form.returnTime = formattedDate;
      this.showPickerReturnTime = false;
    },
    onSubmit (values) {
      console.log('submit', values);
    },
    addUseCar () {
      if (!this.form.rider || !this.form.initiatorPhone) {
        // 如果有必填项未填写，显示错误信息或阻止提交
        dd.device.notification.alert({
          message: "请填写所有必填项！",
          title: "提示",
          buttonName: "确定",

        });

        return;
      }
      //弹窗关闭当前页面
      this.showddclosePage();
      // 将userid转换为数组
      this.form.userid = this.people.map(person => person.emplId);
      this.form.copyUser = this.copyUser.map(person => person.emplId);
      // 如果form.phone是字符串，将其转换为数组
      if (typeof this.form.phone === 'string') {
        this.form.phone = [this.form.phone];
      }
      // 提交表单
      this.$axios.post('/carRental', this.form).then(resp => {

        console.log("提交成功", resp.data);
      });
    },
    //关闭
    showddclosePage () {
      //弹窗提示
      dd.device.notification.alert({
        message: "添加成功",
        title: "提示",
        buttonName: "确定",
        onSuccess: function (res) {
          // 使用钉钉JSAPI关闭当前页面（弹窗）
          dd.biz.navigation.close();
          // 调用成功时回调
          console.log(res)
        },
        onFail: function (err) {
          // 调用失败时回调
          console.log(err)
        }
      });

    },
  },

  mounted () {
    //获取免登授权码
    this.getDDConfigParams();

    //获取身份
    this.getCarmadeof();
  }
}
</script>
<style>
@import "../../css/home.css";

.qrcodeiconcoy {
  position: absolute;
  top: 10px;
  right: 20px;
}
</style>