<template>
    <div class="leave-request-form">
      <h1>请假申请</h1>
      <form @submit.prevent="submitForm">
        <div class="form-group">
          <label for="leave_type_id">请假假种</label>
          <select v-model="form.leaveType" id="leave_type_id">
            <option v-for="type in leaveTypes" :key="type" :value="type">{{ type }}</option>
          </select>
        </div>
        
        <div class="form-group">
        <label for="start_date">申请时间</label>
        <input type="datetime-local" v-model="form.start_date" id="start_date" />
      </div>
      
      <div class="form-group">
        <label for="end_date">返回时间</label>
        <input type="datetime-local" v-model="form.end_date" id="end_date" />
      </div>
        
        <div class="form-group">
          <label for="department">所在部门</label>
          <select v-model="form.department" id="department">
            <option v-for="dept in departments" :key="dept" :value="dept">{{ dept }}</option>
          </select>
        </div>
        
        <div class="form-group">
          <label for="employeeName">请假人员</label>
          <input type="text" v-model="form.employeeName" id="employeeName" />
        </div>
        
        <div class="form-group">
          <label for="reason">请假事由</label>
          <textarea v-model="form.reason" id="reason"></textarea>
        </div>
        
        <button type="submit">提交申请</button>
      </form>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  export default {
    data() {
      return {
        form: {
          leaveType: '',
          startDate: '',
          endDate: '',
          department: '',
          employeeName: '',
          reason: ''
        },
        leaveTypes: ['年休假','病假','事假','婚假','丧假','产假','护理假','补休假','育儿假','独生子女父母护理假','其他假'],
        departments: ['开发部', '财务部', '业务部']
      };
    },
    methods: {  
    submitForm() {  
      axios.post('/apply', this.form).then(resp => {  
        console.log("提交成功", resp.data);  
      }).catch(error => {  
        console.error("提交失败", error);  
      });  
    }  
  }  
}
  </script>
  <style>
  .leave-request-form {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
  }
  
  .form-group {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  
  label {
    flex: 1;
    margin-right: 10px;
  }
  
  input[type="datetime-local"],
  input[type="text"],
  select,
  textarea {
    flex: 2;
    padding: 8px;
    box-sizing: border-box;
  }
  
  textarea {
    height: 100px; /* 可以根据需要调整 */
  }
  
  button {
    padding: 10px 15px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }
</style>