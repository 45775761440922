import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Axios from 'axios'
import VueCookies from 'vue-cookies'
// import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
 
import { initDingH5RemoteDebug } from "dingtalk-h5-remote-debug"
initDingH5RemoteDebug()

// Axios.defaults.baseURL="http://1.95.77.203:8400/dingding";
// Axios.defaults.baseURL="http://192.168.0.18:8400/dingding";
Axios.defaults.baseURL="http://127.0.0.1:8400/dingding";
Vue.use(VueCookies)
Vue.config.productionTip = false
Vue.prototype.$axios=Axios;
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
