<template>
  <div id="app">
    <van-tabbar-item icon="home-o" replace to="/">Home</van-tabbar-item>
    <router-view/>
  </div>
</template>

<script>

import Vue from 'vue';
import {Tabbar, TabbarItem} from 'vant';

Vue.use(Tabbar);
Vue.use(TabbarItem);
// import { GetCode } from '@/views/dingTalk/Dingding.js'
import * as dd from "dingtalk-jsapi";


export default {
  data() {
    return {
      active: 0,
      ddConfig: {}
    };
  },
  methods: {
    async fetchDingdingConfig() {
      try {
        const res = await this.$axios.post('/auth/dingdingUseCar');
        if (res.data.code === 200) {
          this.ddConfig = res.data.data;
          // 解构ddConfig对象的属性，并传入dd.config方法
          dd.config({
            ...this.ddConfig,
            jsApiList: [
              'runtime.info',
              'biz.contact.choose',
              'device.notification.confirm',
              'device.notification.alert',
              'device.notification.prompt',
              'biz.ding.post',
              'biz.util.openLink',
              'biz.contact.departmentsPicker',
              'biz.contact.complexPicker',
              'chooseUserFromList',
              'chooseStaffForPC'
            ]
          });
          dd.error(function (err) {
            
            console.log(JSON.stringify(err))
          })//该方法必须带上，用来捕获鉴权出现的异常信息，否则不方便排查出现的问题
        
        }
      } catch (error) {
        console.error('Failed to fetch Dingding config:', error);
      }
    }
  },
  mounted() {
    this.fetchDingdingConfig();
  }
}
</script>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
