<template>
    <div>
      <p>今天是否是工作日: {{ isWorkingDay ? '是' : '否' }}</p>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        isWorkingDay: false
      };
    },
    created() {
      this.checkIsWorkingDay();
    },
    methods: {
      async checkIsWorkingDay() {
        const date = new Date();
        const year = date.getFullYear();
        const month = date.getMonth() + 1; // 月份是从0开始的，需要加1
        const day = date.getDate();
        const dateString = `${year}-${month}-${day}`;
  
        try {
          const response = await fetch(`https://oapi.dingtalk.com/topapi/workday/get`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              workDate: dateString
            })
          });
  
          const data = await response.json();
          this.isWorkingDay = data.result.isWorkday; // 根据返回结果设置是否为工作日
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
    }
  };
  </script>
  